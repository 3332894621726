<template>
  <div class="container">
    <div class="sovTitle">
      <div class="filter">
        <div>
          <span style="margin-right: 10px">{{ cardTitle }}</span>
          <el-select
            size="mini"
            class="selectClass"
            v-model="timeRange"
            @change="timeChange"
          >
            <el-option
              v-for="(item, index) in optionData"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <svg-icon class-name="brand" class="iconStyle"></svg-icon>
          <span>{{ type }}：</span>
          <span class="brandText">
            <span v-for="(item, index) in brandNameList" :key="index">{{
              item
            }}</span>
          </span>
        </div>
      </div>
      <el-button size="mini" @click="forBack" class="returnBtn">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeRange: "",
      optionData: [
        {
          value: "1mon",
          label: "近1个月",
        },
        {
          value: "3mon",
          label: "近3个月",
        },
        {
          value: "6mon",
          label: "近6个月",
        },
        {
          value: "12mon",
          label: "近12个月",
        },
        {
          value: "thisYear",
          label: "今年至今",
        },
        {
          value: "lastYear",
          label: "去年全年",
        },
      ],
    };
  },
  props: {
    cardTitle: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "品牌",
    },
    brand: {
      type: String,
      default: "",
    },
  },
  computed: {
    brandNameList() {
      return this.brand.map((v) => v.label);
    },
  },
  mounted() {
    this.timeRange = this.$route.query.duration;
  },
  methods: {
    forBack() {
      this.$router.push({ name: "sovCategoryList" });
    },
    timeChange(val) {
      console.log(val, this.timeRange);
      this.$emit("timeChange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
  position: relative;
  top: -10px;
  background-color: #fff;
  height: 62px;
  line-height: 60px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 0 20px;
  color: #11263c;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .sovTitle {
    display: flex;
    align-items: center;

    .filter {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .iconStyle {
        font-size: 16px;
        margin-left: 100px;
        margin-right: 5px;
      }

      .brandText {
        line-height: 59px;
        color: #11263c;

        span {
          padding: 0 10px;
          border-right: 1px solid #d4d0db;
        }

        & :first-child {
          padding-left: 0;
        }

        & :last-child {
          padding-right: 0;
          border-right: none;
        }
      }
    }

    .returnBtn {
      color: #fff;
      width: 74px;
      padding: 0;
      background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
      border-radius: 4px;
    }
  }
}
</style>
